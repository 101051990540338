.moveText {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 8px;
  text-transform: capitalize;
  font-family: sans-serif;
}

.formBackground {
  background: #00000078;
  /* box-shadow: 0px 0px 5px 5px #ffffff33; */
  width: 100%;
  /* max-width: 900px; */
  max-width: 660px;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #585858;
}

.formBox {
  /* padding: 50px 0px 45px; */
  padding: 25px 0px;
  margin: 0px auto;
  width: 100%;
  /* max-width: 560px; */
  max-width: 600px;
}

.formControl {
  height: 50px !important;
  border-radius: 4px !important;
  font-size: 20px !important;
  /* font-weight: 500 !important; */
  font-weight: 400 !important;
  color: #6e6e6e !important;
  color: #6e6e6e !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none;
}

.formControl:focus {
  box-shadow: unset !important;
  border-color: #ffffff !important;
}

.inputGroup {
  /* width: 50px !important; */
  height: 50px !important;
  width: 50px !important;
  /* background: #eeeeee !important; */
  background: #2fc2fe !important;
}

.inputAutoComplete {
  /* width: 90% !important; */
  width: 100% !important;
}

@media only screen and (max-width: 490px) {
  .inputGroup {
    width: 15% !important;
  }
  .inputAutoComplete {
    width: 85% !important;
  }
}

.inputGroup img {
  font-size: 24px;
  margin-left: 4px;
  object-fit: contain;
}

.buttonControls {
  display: flex;
  /* margin-top: 50px; */
}

.leftButton {
  font-weight: 600;
  font-size: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: unset;
  padding: 0px 10px;
  width: 100%;
  height: 50px;
}

.rightButton {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  /* background: linear-gradient(88.85deg, #4770eb 0.73%, #6931df 99.01%); */
  background: linear-gradient(88.85deg, #0f7dff 0.73%, #2fc2fe 99.01%);
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  padding: 0px 10px;
  width: 100%;
  height: 50px;
}

@media only screen and (min-width: 380px) and (max-width: 460px) {
  .leftButton {
    font-size: 15px;
  }

  .rightButton {
    font-size: 15px;
    /* margin-left: 15px; */
  }
}

.formBox .arrowIcon {
  font-size: 24px;
  color: #ffffff;
  margin: 10px 12px;
}

.ratingPointsWrapper {
  background-color: #f2f2f2;
}
.ratingPointsSection {
  /* display: flex;
  gap: 12px;
  padding: 28px 0px; */
  background-color: #252525;
  color: white;
  margin-top: 42px;
  border-radius: 5px;
  padding: 16px;
}
.ratingPointsItems {
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 10px;
  min-height: 224px;
}
.ratingImages {
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 991px) {
  .ratingImages img {
    height: 18px;
  }
  .ratingPointsItems {
    min-height: 184px;
  }
  .ratingTitle {
    font-size: 12px;
  }
  .ratingDescription p {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .ratingPointsItems {
    min-height: 150px;
  }
}

@media screen and (max-width: 575px) {
  .ratingPointsItems {
    min-height: unset;
  }
}

.ratingDescription {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.ratingTitle {
  /* font-weight: 700; */
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
}

/* .ratingDescription p{
  font-weight: 600;
  color: #828282;
} */

.ratingDescription {
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  max-width: 600px;
  text-align: start;
  font-family: "Inter";
}

/* about css */
.aboutPage h2 {
  font-weight: 400;
  font-size: 30px;
  /* text-align: center; */
  color: #000000;
  margin-top: 30px;
  margin-bottom: 82px;
}

.aboutPage h2::after {
  content: "";
  display: block;
  width: 70px;
  margin-top: 4px;
  padding-top: 1px;
  border-bottom: 4px solid transparent;
  background-image: linear-gradient(88.85deg, #4770eb 0.73%, #6931df 99.01%),
    linear-gradient(0deg, #0f7dff, #0f7dff);
}

.aboutBox {
  /* box-shadow: 0px 4px 8px 0px #00000033; */
  /* padding: 40px 20px 10px; */
  padding: 15px 0px 20px;
  /* background: #ffffff; */
  display: flex;
  position: relative;
  /* top: -50px; */
  border-radius: 16px;
}

.aboutBackground {
  background: #f2f2f2;
  margin: 30px 0px;
}

.aboutBox .aboutImage {
  width: 100%;
}

.aboutBox .aboutImage img {
  width: 100%;
  height: 440px;
  object-fit: cover;
  border-radius: 12px;
  aspect-ratio: 3 / 2;
  /* border-radius: 50px 0px 50px 0px; */
}

.aboutCategories {
  background: #0f7dff05;
  padding: 30px 0px 40px;
}

.aboutPage h5 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 30px;

  text-align: center;
}

.aboutPage h5::after {
  margin: auto;

  content: "";
  display: block;
  width: 70px;
  margin-top: 4px;
  padding-top: 1px;
  border-bottom: 4px solid transparent;
  /* background-image: linear-gradient(88.85deg, #4770eb 0.73%, #6931df 99.01%),
    linear-gradient(0deg, #0f7dff, #0f7dff); */
  background: linear-gradient(88.85deg, #2fc2fe 0.73%, #0f7dff 99.01%);
  border-radius: 10px;
}

.categoryImg {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.categoryImg img {
  width: 100%;
}

.categoryImgSection {
  position: relative;
  cursor: pointer;
}

.categoryImgSection img {
  border-radius: 6px;
}

.aboveText {
  position: absolute;
  bottom: 0px;
  left: 16px;
  font-weight: 600;
  font-size: 26px;
  font-family: "Inter";
  color: #ffffff;
}

.categoryImg1 {
  width: 100%;
  height: 416px;
  object-fit: cover;
  aspect-ratio: 657 / 416;
}

.categoryImg2 {
  width: 100%;
  height: 322px;
  object-fit: cover;
  aspect-ratio: 532 / 322;
}

.categoryImg3 {
  width: 100%;
  height: 240px;
  object-fit: cover;
  aspect-ratio: 533 / 240;
}

.categoryImg p {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: 30px;
  color: #ffffff;
  line-height: 36.31px;
}

.aboutWorks {
  padding: 40px 0px 0px;
}

.worksImg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.worksImg img {
  width: 100%;
}

.worksImg h6 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 17px;
  color: #000000;
  line-height: 21.78px;
  text-transform: capitalize;
}

.worksImg p {
  color: #333333;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

.aboutDiscription strong {
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}

.aboutDiscription ul li {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  line-height: 36.31px;
}

/* chat feture */
.chatFeature {
  text-align: end;
  padding-bottom: 30px;
  cursor: pointer;
  background-color: #eceff3;
}
.chatFeature img {
  margin-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categoryImg p {
    font-size: 22px;
    line-height: 28.31px;
  }

  .aboutPage h5 {
    font-size: 22px;
    margin-bottom: 21px;
  }

  .worksImg h6 {
    font-size: 15px;
  }

  .worksImg p {
    font-size: 12px;
    line-height: 22px;
  }

  /* .aboutBox .aboutImage {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
  } */

  /* .aboutBox .aboutImage img {
    width: 100%;
    max-width: 400px;
  } */

  .aboutDiscription strong {
    font-size: 15px;
    line-height: 26px;
    padding-top: 10px;
  }

  .aboutDiscription ul li {
    font-size: 13px;
    line-height: 22px;
  }

  .aboutDiscription {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .aboutPage h2 {
    font-size: 35px;
  }

  /* .aboutBox .aboutImage {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
  } */

  /* .aboutBox .aboutImage img {
    width: 100%;
    max-width: 400px;
  } */

  .aboutDiscription strong {
    font-size: 15px;
    line-height: 26px;
    padding-top: 10px;
  }

  .aboutDiscription ul li {
    font-size: 13px;
    line-height: 22px;
  }

  .aboutPage h5 {
    /* font-weight: 400; */
    font-size: 23px;
    margin-bottom: 13px;
  }

  .categoryImg p {
    font-size: 13px;
    line-height: 19.31px;
  }

  .worksImg h6 {
    font-size: 12px;
  }

  .worksImg p {
    font-size: 10px;
    line-height: 20px;
  }

  .chatFeature {
    padding-bottom: 17px;
  }

  .aboutDiscription {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .categoryImg {
    max-width: 400px;
    margin: 10px auto;
  }

  .categoryImg img {
    width: 100%;
    max-width: 400px;
  }

  .formControl {
    font-size: 15px !important;
  }

  .moveText {
    font-size: 22px;
  }

  .aboutPage h2 {
    font-size: 35px;
  }

  /* .aboutBox .aboutImage {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
  } */

  /* .aboutBox .aboutImage img {
    width: 100%;
    max-width: 400px;
  } */

  .aboutDiscription {
    margin-top: 15px;
  }

  .aboutDiscription strong {
    font-size: 15px;
    line-height: 26px;
    padding-top: 10px;
  }

  .aboutDiscription ul li {
    font-size: 13px;
    line-height: 22px;
  }

  .aboutPage h5 {
    /* font-weight: 400; */
    font-size: 23px;
    margin-bottom: 13px;
  }

  .categoryImg p {
    font-size: 25px;
    line-height: 32.31px;
  }

  .worksImg {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    margin-bottom: 22px;
  }

  .worksImg img {
    width: 100%;
    max-width: 400px;
  }

  .chatFeature {
    padding-bottom: 17px;
  }

  .worksImg h6 {
    font-size: 17px;
    line-height: 22px;
  }

  .worksImg p {
    font-size: 12px;
    line-height: 20px;
  }
  .topSubHeadTitle {
    font-size: 25px;
  }
}

.topHeadTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
}

.topSubHeadTitle {
  /* text-transform: uppercase; */
  font-family: "Inter";
  font-size: 50px;
}

.topSuggestText {
  font-size: 25px;
  /* font-weight: 600; */
  font-weight: 400;
  font-family: "Inter";
  max-width: 600px;
  /* text-align: center; */
  margin: 0 auto;
  margin-bottom: 26px;
  color: #ffffff;
  line-height: 35px;
}

.topServiceText {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: capitalize;
  font-family: sans-serif;

  text-align: center;
  color: #ffffff;
}

.moveTextInner {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 16px;
  font-family: sans-serif;
}

.ratingSection {
  padding: 0 12px 12px;
}

.ratingMidSection {
  display: flex !important;
  flex-direction: column;
  align-items: start;
  gap: 14px;
  margin-top: 12px;
  background: linear-gradient(88.85deg, #0f7dffb2 0.73%, #2fc2feb2 99.01%);
  min-height: 214px;
  border-radius: 4px;
  padding: 16px;
}

.worksSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: unset !important;
  border: 1px solid #d3d3d3;
}
.worksSection div {
  width: 100%;
}
.worksSectionText {
  background-color: #f8f8f8;
  min-height: 120px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter";
  width: 100%;
}

.worksSection img {
  width: 100%;
  border-radius: unset !important;
  object-fit: cover;
  height: auto;
  aspect-ratio: 39 / 37;
}

.reverseOrder {
  flex-direction: column-reverse; /* Reverse layout for odd items on desktop */
}

.loveDoingText {
  min-height: 162px;
  font-size: 32px;
  font-weight: 600;
  max-width: 400px;
  font-family: "Inter";
}

.aboutText {
  padding-bottom: 12px;
}

.ratingPointsSections {
  margin-top: 22px;
}

.sliderBtns {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 22px;
}

.sliderBtns svg {
  cursor: pointer;
  height: 44px;
  width: 44px;
}

@media only screen and (max-width: 575px) {
  .worksSectionText {
    justify-content: center;
  }
  .categoryImgSection img {
    height: auto;
  }
  .reverseOrder {
    flex-direction: column; /* Reverse layout for odd items on desktop */
  }
  .moveTextInner {
    text-align: center;
  }
  .loveDoingText {
    font-size: 22px;
  }
}

@media only screen and (max-width: 991px) {
  .categoryImgSection img {
    height: auto;
  }
  .aboutWorks {
    padding: 30px 0px 0px;
  }
  .loveDoingText {
    min-height: unset;
    max-width: unset;
    font-size: 23px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
  }

  .loveDoingText::after {
    margin: auto;
    content: "";
    display: block;
    width: 70px;
    margin-top: 4px;
    padding-top: 1px;
    border-bottom: 4px solid transparent;
    background: linear-gradient(88.85deg, #2fc2fe 0.73%, #0f7dff 99.01%);
    border-radius: 10px;
  }
}

.imageSection {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 74px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}
