.topHead {
  display: flex;
  justify-content: space-between;
  margin: 0px 80px;
}
.tabCount {
  font-size: 16px;
  font-weight: 600;
}
.tabCount span {
  color: #626262;
}

.backarrow {
  display: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.backarrow svg {
  box-shadow: 0px 0px 4px 0px #00000033;
  border-radius: 50%;
  font-size: 30px;
  padding: 8px;
  cursor: pointer;
}

.topHeading {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0px 10px;
}

.tabCounting {
  display: none;
}

.topSubHead {
  /* background-color: #fbfbfb; */
  height: max-content;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 30px;
  padding-top: 22px;
}
.topSubHeadTitle {
  font-size: 30px;
  font-weight: 700;
  color: #4770eb;
  /* text-transform: capitalize; */
}
.topSubHeadDesc {
  margin: 12px 60px 0px 60px;
  font-size: 15px;
  font-weight: 400;
  color: #828282;
}
.topSuggestText {
  font-size: 15px;
  font-weight: 400;
  /* margin-top: 4px; */
}
.topServiceText {
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
}
.imageBox {
  margin: 0px 12px 0px 12px;
  display: flex;
  justify-content: center;
  gap: 90px;
  align-items: center;
}
.image {
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  height: 100%;
  margin: 15px 35px;
  cursor: pointer;
}

.imageContainer p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.categoryListSection {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 0 90px;
  margin: 20px 0px 20px 0px;
}
.tabContainer {
  /* border: 2px solid #828282; */
  width: 100%;
}
.tabContainer p {
  border: 2px solid #4770eb;
  border-radius: 10px;
  padding: 10px 32px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  color: #4770eb;
}
@media screen and (max-width: 815px) {
  .image {
    max-width: 90px;
    max-height: 90px;
    margin: 15px 35px;
  }
  .imageBox {
    gap: 30px;
  }
}
@media screen and (max-width: 630px) {
  .image {
    max-width: 70px;
    max-height: 70px;
    margin: 15px 35px;
  }
  .imageContainer p {
    font-size: 12px;
    font-weight: 500;
  }
  .imageBox {
    gap: 20px;
  }
}
@media screen and (max-width: 470px) {
  .topSubHeadDesc {
    margin: 12px 10px 0px 10px;
  }
  .mainFieldContainer {
    margin: 0px 10px !important;
  }
  .topSubHeadTitle {
    font-size: 25px;
  }
  .image {
    margin: 0px 35px;
    max-width: 80px;
    max-height: 80px;
  }
  .imageBox {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .backarrow {
    display: block !important;
  }
  .topHead {
    display: none;
  }
  .tabCounting {
    display: block;
  }
  .topHeading {
    justify-content: space-between;
  }
  .stopsContainer {
    margin: 20px 10px 0px 10px !important;
  }
  .addStopSection {
    padding: 6px 20px !important;
  }
  .nextBtnFooter {
    margin: 0px 10px !important;
  }
}
@media screen and (max-width: 400px) {
  .image {
    max-width: 70px;
    max-height: 70px;
  }
  .imageBox {
    gap: 5px !important;
  }
}
.mainFieldContainer {
  margin: 0px 90px;
  background-color: #4770eb;
  color: white;
  border-radius: 6px;
}
.fieldContainer {
  padding: 0px 80px;
}
.addStopSection {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  color: #4770eb;
  background-color: #fbfbfb;
  font-size: 20px;
  font-weight: 600;
  padding: 6px 90px;
}
.addStopSection span {
  cursor: pointer;
}
.stopsContainer {
  margin: 20px 90px 0px 90px;
  background-color: #4770eb;
  color: white;
  border-radius: 8px;
}
.stopsContainerFields {
  padding: 8px 80px;
}

@media screen and (max-width: 570px) {
  .mainFieldContainer {
    margin: 0px 40px;
  }
  .fieldContainer {
    padding: 20px 40px;
  }
  .stopsContainer {
    margin: 20px 40px 0px 40px;
  }
  .stopsContainerFields {
    padding: 8px 40px;
  }
  .imageBox {
    gap: 10px;
  }
  .categoryListSection {
    padding: 0 20px;
  }
  .nextBtnFooter {
    margin: 0px 40px;
  }
}

.nextBtnFooter {
  display: flex;
  justify-content: flex-end;
  padding: 14px 0px;
  margin: 0px 90px;
}

.nextBtn {
  padding: 8px 64px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(to left, #0f7dff, #6931df);
  cursor: pointer;
}

.nextBtn span {
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(to right, #6931df, #0f7dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nextBtn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: white;
  z-index: -1;
  transition: 200ms;
}

.chatFeature {
  text-align: end;
  margin-right: 24px;
  cursor: pointer;
}
.errorMessageLocation {
  margin-bottom: -29px;
  text-align: left;
  color: #dc3545;
  font-weight: bold;
  font-size: 12px;
}
.errorMessage {
  margin-top: -19px;
  margin-bottom: -29px;
  text-align: left;
  color: #dc3545;
  font-weight: bold;
  font-size: 12px;
}
.addViaBtn {
  text-align: left;
  margin-bottom: 12px;
}
.addViaBtn span {
  background-color: white;
  color: #4770eb;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 32px;
  border-radius: 8px;
  cursor: pointer;
}
.viaContainer {
  margin-bottom: 12px;
}

@media screen and (max-width: 952px) {
  .tabContainer p {
    padding: 10px 4px;
  }
}

@media screen and (max-width: 780px) {
  .categoryListSection {
    flex-direction: column;
    /* padding: 0 40px; */
  }
}

@media only screen and (min-width: 992px) and (max-width: 1150px) {
  .fieldContainer {
    padding: 20px 40px;
  }
}
@media only screen and (min-width: 570px) and (max-width: 688px) {
  .fieldContainer {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 991px) {
  .addViaBtn {
    margin-top: 12px;
  }
}

@media screen and (max-width: 570px) {
  .nextBtn {
    padding: 8px 42px;
  }
}
