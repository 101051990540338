.loginPara {
  font-size: 16px;
  font-weight: 500;
  color: #626262;
  line-height: 30px;
  text-align: center;
}

.forgotPassword {
  text-align: center;
  /* margin: 20px 0px; */
  margin: 10px 0px;
}

.forgotPassword a {
  font-weight: 400;
  font-size: 15px;
  line-height: 18.15px;
  text-decoration: none !important;
}

.bottomText {
  text-align: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  /* margin-top: 20px; */
  margin-top: 10px;
  margin-bottom: 0px !important;
}

.bottomText a {
  text-decoration: none !important;
  color: #0094f6;
  font-weight: 600;
}

.formBlocks {
  /* border-radius: 50px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  width: 100%;
  max-width: 820px;
  margin: 0px auto;
  padding-top: 11px; */

  border-radius: 10px;
  max-width: 485px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

.logoImage {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.logoImage img {
  max-width: 159px;
  /* height: 90px; */
}

.loginType {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
}
