.headerSection {
  box-shadow: 0px 1px 4px 0px #00000026;
  /* background-color: #ffffff; */
  background-color: #0c0c0c;
  margin-left: auto;
  margin-right: auto;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 999;
}

.loginButton {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  /* background: linear-gradient(88.85deg, #4770eb 0.73%, #6931df 99.01%); */
  background: #2fc2fe;
  border-radius: 40px;
  border: unset;
  padding: 8px 22px;
  margin-left: 30px;
}

.toggleButton {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  background: linear-gradient(88.85deg, #4770eb 0.73%, #6931df 99.01%);
  border-radius: 4px;
  border: unset;
  padding: 8px 8px;
  margin-left: 20px;
}

/* sidebar css */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 60px;
  box-shadow: 0px 1px 4px 0px #00000026;
  transition: width 0.2s ease;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #828282;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 0px;
  right: 17px;
  font-size: 32px;
  margin-left: 50px;
  color: #ffffff;
}

.buttonCenter {
  padding: 8px 8px 8px 32px;
}

@media only screen and (max-width: 991px) {
  .loginButton {
    width: 127px;
    margin-left: 0px !important;
    margin-top: 12px;
  }
}

@media screen and (max-width: 991px) {
  .navbarshow {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .sidebarShow {
    display: none;
  }
}

/* after login header css */

.navbarAfterLogin svg {
  background: #f7f7f7;
  border: 0.5px solid #d9d9d9;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  margin-left: 20px;
}

.navbarAfterLogin .profileImg {
  border: 2px solid #4770eb;
  /* width: 40px;
  height: 40px; */
  border-radius: 50px;
  margin-left: 20px;
  cursor: pointer;
}

.navbarAfterLogin .profileImg img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}

.logoImg img {
  width: 100%;
  max-width: 170px;
}

.headerLogo {
  width: 100%;
  max-width: 155px;
  height: 50px;
}
.smallLogo {
  cursor: pointer;
  width: 100%;
  max-width: 155px;
  height: 50px;
  padding: 8px;
  object-fit: contain;
}

.open {
  /* width: 250px; */
}

.socialIcons {
  display: flex;
  justify-content: center;
}
.socialIcons a {
  padding: 0px;
}
.socialIcons img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.sidenav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  background-color: #0c0c0c;
  /* border: 1px solid gray; */
}
.tabHrDivider {
  margin: 0px 16px 0px 16px;
  color: #ffffff;
}
.sidebarNotification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0c0c0c;
}

.sideLogo {
  display: flex;
  justify-content: center;
  margin-left: -32px !important;
}

.sideLogo img {
  height: 90px;
  width: 90px;
  object-fit: contain;
}

.vanLoginImg {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-left: 25px;
}

.vanLoginImgSidebar {
  width: 46px;
  height: 46px;
  cursor: pointer;
  margin: 5px 0px 5px 30px;
}
