.infoWrapper {
  padding: 10px;
}

.infoWrapper h1 {
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 25px;
  margin-top: 20px;
  text-align: center;
}
